import * as React from 'react';
import styled from 'styled-components';
import { compose, space, color, layout, typography, SpaceProps, ColorProps, LayoutProps, TypographyProps } from 'styled-system';
import { nanoid } from 'nanoid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement> & {
  title?: any;
}>(({
  title,
  ...props
}, svgRef) => {
  const [titleId] = React.useState(() => title ? nanoid() : undefined);
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="1em" height="1em" ref={svgRef} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path stroke="#323232" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M7.255 12.964l3.884-2.913 1.644 2.448 3.962-2.97" /><path stroke="#323232" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M3 11.29c0 2.684 1.42 5.046 3.599 6.535-.001.855.001 2.007.001 3.216l3.531-1.747a9.896 9.896 0 001.869.179c4.952 0 9-3.643 9-8.182 0-4.54-4.048-8.182-9-8.182S3 6.752 3 11.29z" clipRule="evenodd" /></svg>;
});
export const IconInterfaceEssentialFacebookMessanger = styled(SvgComponent)<IconProps>({
  flex: 'none',
  verticalAlign: 'middle'
}, compose(space, color, layout, typography));