import { fromScreen } from '@doltech/core/lib/responsive/responsive.util';
import cl from 'classnames';
import * as React from 'react';
import styled from 'styled-components';

import { colorsV2 } from '../../../../colors-v2';
import { Typography } from '../../../../DesignSystemV2/Typography';
import { Separator } from '../../Content/Separator';
import { RowLink } from '../RowLink/RowLink';

const Main = styled.div`
  padding: 32px 0;
  color: ${colorsV2.gray100};
  display: grid;
  grid-auto-flow: row;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;

  .links {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    gap: 8px;

    a {
      color: ${colorsV2.gray100};
      &:hover {
        color: ${colorsV2.primary100};
      }
    }
  }

  .footer-nested-links {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    gap: 8px;
  }

  ${fromScreen(840)} {
    padding: 32px 0 40px;
    grid-auto-flow: column;
    justify-content: space-between;
  }
`;

interface ContentItem {
  link: string;
  text: string | JSX.Element;
  external: boolean;
  noFollow?: boolean;
  icon?: string;
}

const footerCopyRightLinks: ContentItem[] = [
  {
    text: 'Giới thiệu',
    link: '/gioi-thieu',
    noFollow: true,
    external: true,
  },
  {
    text: 'Chính sách bảo mật',
    link: '/chinh-sach-bao-mat',
    noFollow: true,
    external: true,
  },
  {
    text: 'Điều khoản Sử dụng',
    link: '/dieu-khoan-su-dung',
    noFollow: true,
    external: true,
  },
];

interface FooterCopyRightProps extends React.HTMLAttributes<HTMLDivElement> {
  align?: 'left' | 'center';
  data?: any[];
}

export const FooterCopyRight = (props: FooterCopyRightProps) => {
  const { className, align = 'left', data = footerCopyRightLinks } = props;

  return (
    <Main id="footer-copyright" className={cl('footer-copy-right', className, align)}>
      <div className="title">
        <Typography.Text variant="regular/14">
          © {new Date().getFullYear()} DOL English. All rights reserved.
        </Typography.Text>
        <div>
          <a
            href="//www.dmca.com/Protection/Status.aspx?ID=36c8b284-aa13-4f7c-96a6-e7e419ec8411"
            title="DMCA.com Protection Status"
            className="dmca-badge"
            target="_blank"
            rel="noreferrer"
          >
            <img
              width={102}
              height={24}
              src="https://images.dmca.com/Badges/dmca_protected_sml_120i.png?ID=36c8b284-aa13-4f7c-96a6-e7e419ec8411"
              alt="DMCA.com Protection Status"
            />
          </a>
          <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"></script>
        </div>
      </div>

      {data?.length > 0 && (
        <div className="links">
          <div className="footer-nested-links">
            <RowLink variant="regular/14" row={footerCopyRightLinks[0]} />
            <Separator variant="vertical" bg={colorsV2.gray100} />
            <RowLink variant="regular/14" row={footerCopyRightLinks[1]} />
          </div>
          <Separator display={['flex', 'flex', 'flex']} variant="vertical" bg={colorsV2.gray100} />
          <RowLink variant="regular/14" row={footerCopyRightLinks[2]} />
        </div>
      )}
    </Main>
  );
};
