import { IconAlertsPhone } from '@doltech/icons/IconAlertsPhone';
import { IconBrandsFacebook } from '@doltech/icons/IconBrandsFacebook';
import { IconBrandsLogoYoutube1 } from '@doltech/icons/IconBrandsLogoYoutube1';
import { IconBrandsTiktok1 } from '@doltech/icons/IconBrandsTiktok1';
import { IconContentPin } from '@doltech/icons/IconContentPin';
import { IconInterfaceEssentialFacebookMessanger } from '@doltech/icons/IconInterfaceEssentialFacebookMessanger';

export const FooterIconMapper = {
  pin: IconContentPin,
  messenger: IconInterfaceEssentialFacebookMessanger,
  phone: IconAlertsPhone,
  facebook: IconBrandsFacebook,
  youtube: IconBrandsLogoYoutube1,
  tiktok: IconBrandsTiktok1,
};
