import { IconAlertsPhone } from '@doltech/icons/IconAlertsPhone';
import { IconBrandsFacebook } from '@doltech/icons/IconBrandsFacebook';
import { IconBrandsLogoYoutube1 } from '@doltech/icons/IconBrandsLogoYoutube1';
import { IconBrandsTiktok1 } from '@doltech/icons/IconBrandsTiktok1';
import { IconContentPin } from '@doltech/icons/IconContentPin';
import { IconInterfaceEssentialFacebookMessanger } from '@doltech/icons/IconInterfaceEssentialFacebookMessanger';
import cl from 'classnames';
import * as React from 'react';

import { Link } from '../../../../Link';
import { ResponsiveTypography } from '../../../../Typography/v3/ResponsiveTypography';
import classes from './RowLink.module.less';

const IconMapper = {
  pin: IconContentPin,
  messenger: IconInterfaceEssentialFacebookMessanger,
  phone: IconAlertsPhone,
  facebook: IconBrandsFacebook,
  youtube: IconBrandsLogoYoutube1,
  tiktok: IconBrandsTiktok1,
};

interface RowLinkProps {
  row?: any;
  variant?: any;
  prefetch?: boolean;
  color?: any;
}

export const RowLink = ({
  row,
  color,
  variant = 'regular/16-24',
  prefetch = true,
}: RowLinkProps) => {
  const { text, link, external, noFollow, icon } = row;
  const Icon = IconMapper[icon];
  return (
    <Link
      prefetch={prefetch}
      rel={noFollow ? 'nofollow' : ''}
      target={external ? '_blank' : '_self'}
      className={cl(classes.rowLink, 'dol-row-link')}
      key={text + link}
      href={link || '#'}
    >
      {icon && (
        <div className="icon">
          <Icon />
        </div>
      )}
      <ResponsiveTypography.Text color={color} variant={variant}>
        {text}
      </ResponsiveTypography.Text>
    </Link>
  );
};
