import * as React from 'react';
import styled from 'styled-components';
import { compose, space, color, layout, typography, SpaceProps, ColorProps, LayoutProps, TypographyProps } from 'styled-system';
import { nanoid } from 'nanoid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement> & {
  title?: any;
}>(({
  title,
  ...props
}, svgRef) => {
  const [titleId] = React.useState(() => title ? nanoid() : undefined);
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="1em" height="1em" ref={svgRef} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fill="currentColor" fillRule="evenodd" d="M19.645 12.48c-.21 0-.429-.067-.639-.114a8.988 8.988 0 01-1.249-.373 1.905 1.905 0 00-2.364.956l-.21.43a11.617 11.617 0 01-2.536-1.912 11.64 11.64 0 01-1.907-2.544l.4-.268a1.915 1.915 0 00.954-2.37 9.89 9.89 0 01-.372-1.254 7.101 7.101 0 01-.114-.65 2.863 2.863 0 00-2.86-2.38h-2.86a2.857 2.857 0 00-2.175.973 2.87 2.87 0 00-.686 2.287C4.072 13.5 10.557 19.982 18.777 21h.363A2.856 2.856 0 0022 18.122v-2.869a2.866 2.866 0 00-2.355-2.773zM20 18.052c0 .27-.117.527-.321.705a.995.995 0 01-.775.235c-7.279-.929-13-6.65-13.898-13.9-.03-.28.055-.558.236-.772A.947.947 0 015.951 4h2.834c.453-.01.85.302.945.743.038.257.085.511.142.762.109.496.254.983.434 1.458l-1.322.611a.942.942 0 00-.463 1.251 13.66 13.66 0 006.613 6.584.95.95 0 00.718 0 .943.943 0 00.539-.49l.586-1.316c.488.174.987.318 1.492.433.252.056.508.103.766.14.443.095.756.49.746.941L20 18.052z" clipRule="evenodd" /></svg>;
});
export const IconAlertsPhone = styled(SvgComponent)<IconProps>({
  flex: 'none',
  verticalAlign: 'middle'
}, compose(space, color, layout, typography));