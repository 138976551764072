import cl from 'classnames';
import * as React from 'react';
import { ExpandCollapseAnimation } from '../../../../../examples/Animation/ExpandCollapse';
import { ResponsiveTypography } from '../../../../Typography/v3/ResponsiveTypography';
import { RowLink } from '../RowLink/RowLink';

export const SingleColumn = ({ data }) => {
  const [isColumnExpanded, setColumnExpanded] = React.useState(true);

  return (
    <div className={cl('column', { collapsed: !isColumnExpanded })}>
      <div
        aria-hidden
        onClick={() => {
          if (window.innerWidth < 1194) return;
          setColumnExpanded((oldExpand) => !oldExpand);
        }}
        className="title sub"
      >
        <ResponsiveTypography.Text variant="semi-bold/16-24">
          {data.title}
        </ResponsiveTypography.Text>
        {/* <div className="icon-arrow">
          <IconChevronChevronDown />
        </div> */}
      </div>
      <ExpandCollapseAnimation expanded={isColumnExpanded}>
        <nav className="link-list">
          {data.content
            .filter(({ disabled }) => !disabled)
            .map((row, rowIndex) => (
              <RowLink variant="regular/14-20" prefetch={false} key={rowIndex} row={row} />
            ))}
        </nav>
      </ExpandCollapseAnimation>
    </div>
  );
};
