import styled from 'styled-components';
import * as React from 'react';
import cl from 'classnames';

const Main = styled.div`
  overflow: hidden;
  transition: max-height 0.15s ease-out;
  max-height: 0;
  &.expanded {
    max-height: initial;
    transition: max-height 0.25s ease-in;
    overflow: initial;
  }
`;

interface ExpandCollapseProps extends React.HTMLAttributes<HTMLDivElement> {
  initial?: any;
  expanded: boolean;
  children: React.ReactNode;
}
export const ExpandCollapseAnimation = ({
  className,
  expanded,
  children,
  ...rest
}: ExpandCollapseProps) => {
  return (
    <Main className={cl(className, { expanded })} {...rest}>
      {children}
    </Main>
  );
};
