import { fromScreen } from '@doltech/core/lib/responsive/responsive.util';
import { css } from 'styled-components';
import { colorsV2 } from '../../../colors-v2';

export const SharedFooterStylesV2 = css`
  &&& {
    .my-link {
      color: ${colorsV2.white100};
    }
    .my-link,
    .dol-row-link {
      &:hover {
        color: ${colorsV2.blue80};
        > span {
          color: ${colorsV2.blue80};
        }
      }
    }
  }
  background-color: ${colorsV2.black100};

  .brand-logo-footer {
    margin-bottom: 32px;
  }

  .responsive-section {
    width: 100%;
    padding: 40px 16px 32px;
    ${fromScreen(540)} {
      padding: 40px 32px 32px;
    }
    ${fromScreen(1144)} {
      padding: 40px 0 32px;
      width: 1080px;
    }
  }

  #footer-three-column {
    display: grid;
    row-gap: 16px;
    border-bottom: 1px solid ${colorsV2.gray100};
    padding-bottom: 40px;
    ${fromScreen(776)} {
      display: grid;
      column-gap: 24px;
      grid-auto-flow: column;
      justify-content: space-between;
      margin: 0;
      grid-template-columns: minmax(0, 1fr) 440px;
    }
    ${fromScreen(1144)} {
      grid-template-columns: minmax(0, 1fr) 512px;
    }
  }

  .column {
    border-bottom: 1px solid ${colorsV2.gray100};
    padding-bottom: 16px;
    ${fromScreen(776)} {
      border-bottom: none;
      padding-bottom: 0;
    }
    &.column-right {
      border-bottom: none;
      white-space: pre-wrap;
      word-break: break-word;
    }

    > .header {
      color: ${colorsV2.gray100};
      margin-bottom: 16px;
      > .header-content {
        display: grid;
        gap: 8px;
      }
    }
    > .title {
      color: ${colorsV2.gray100};
      margin-bottom: 12px;
      display: grid;
      align-items: center;
      grid-template-columns: 1fr max-content;

      .icon-arrow {
        font-size: 24px;
        line-height: 0;
        transition: all 0.3s ease;
        transform: rotate(180deg);
        ${fromScreen(776)} {
          display: none;
        }
      }
    }

    & a > span {
      white-space: pre-wrap;
    }

    .link-list {
      display: grid;
      grid-template-rows: auto;
      row-gap: 8px;
      > * {
        color: ${colorsV2.white100};
        &:hover {
          color: ${colorsV2.blue80};
        }
      }
    }

    &.collapsed {
      .title {
        margin-bottom: 0;
        .icon-arrow {
          transform: rotate(0deg);
        }
      }
    }
  }

  .footer {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(3, 40px);
    margin-bottom: 24px;
    .btn-icon {
      transition: all 0.2s ease;
      width: 40px;
      height: 40px;
      display: grid;
      place-items: center;
      background-color: ${colorsV2.black80};
      color: ${colorsV2.gray100};
      border-radius: 50%;
      font-size: 24px;
      line-height: 0;
      &:hover {
        transform: scale(0.95);
      }
      &:active {
        transform: scale(0.9);
      }
    }
  }

  .column-left-n-middle_right,
  .column-middle-n-right,
  .column-list {
    display: grid;
    grid-auto-rows: max-content;
    row-gap: 16px;
  }

  .column-left-n-middle_right {
    ${fromScreen(776)} {
      column-gap: 8px;
      grid-template-columns: 216px 1fr;
    }
  }
  .column-middle-n-right {
    .column-right .column {
      border-bottom: none;
      padding-bottom: 0;
    }
    ${fromScreen(776)} {
      column-gap: 16px;
      grid-template-columns: 216px 216px;
      justify-content: space-between;
    }
  }
`;

export const OldSharedFooterStyles = css`
  &&& {
    .my-link {
      color: ${colorsV2.white100};
    }
    .my-link,
    .dol-row-link {
      &:hover {
        color: ${colorsV2.blue80};
        > span {
          color: ${colorsV2.blue80};
        }
      }
    }
  }
  background-color: ${colorsV2.black100};

  .brand-logo-footer {
    margin-bottom: 32px;
  }

  .responsive-section {
    width: 100%;
    padding: 40px 16px 32px;
    ${fromScreen(540)} {
      padding: 40px 32px 32px;
    }
    ${fromScreen(1194)} {
      padding: 40px 0 32px;
      width: 1080px;
    }
  }

  #footer-three-column {
    display: grid;
    row-gap: 16px;
    border-bottom: 1px solid ${colorsV2.gray100};
    padding-bottom: 40px;
    ${fromScreen(768)} {
      display: grid;
      column-gap: 40px;
      grid-auto-flow: column;
      justify-content: space-between;
      margin: 0;

      grid-template-columns: minmax(0, 1fr) 436px;
    }
    ${fromScreen(1128)} {
      grid-template-columns: 352px minmax(0, 1fr);
    }
  }

  .column {
    border-bottom: 1px solid ${colorsV2.gray100};
    padding-bottom: 16px;
    ${fromScreen(768)} {
      border-bottom: none;
      padding-bottom: 0;
    }
    &.column-right {
      border-bottom: none;
      white-space: pre-wrap;
      word-break: break-word;
    }

    > .header {
      color: ${colorsV2.gray100};
      margin-bottom: 16px;
      > .header-content {
        display: grid;
        gap: 8px;
      }
    }
    > .title {
      color: ${colorsV2.gray100};
      margin-bottom: 12px;
      display: grid;
      align-items: center;
      grid-template-columns: 1fr max-content;

      .icon-arrow {
        font-size: 24px;
        line-height: 0;
        transition: all 0.3s ease;
        transform: rotate(180deg);
        ${fromScreen(1194)} {
          display: none;
        }
      }
    }

    & a > span {
      white-space: pre-wrap;
    }

    .link-list {
      display: grid;
      grid-template-rows: auto;
      row-gap: 8px;
      > * {
        color: ${colorsV2.white100};
        &:hover {
          color: ${colorsV2.blue80};
        }
      }
    }

    &.collapsed {
      .title {
        margin-bottom: 0;
        .icon-arrow {
          transform: rotate(0deg);
        }
      }
    }
  }

  .footer {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(3, 40px);
    margin-bottom: 24px;
    .btn-icon {
      transition: all 0.2s ease;
      width: 40px;
      height: 40px;
      display: grid;
      place-items: center;
      background-color: ${colorsV2.black80};
      color: ${colorsV2.gray100};
      border-radius: 50%;
      font-size: 24px;
      line-height: 0;
      &:hover {
        transform: scale(0.95);
      }
      &:active {
        transform: scale(0.9);
      }
    }
  }

  .column-left-n-middle_right,
  .column-middle-n-right,
  .column-list {
    display: grid;
    grid-auto-rows: max-content;
    row-gap: 16px;
    ${fromScreen(768)} {
      row-gap: 32px;
    }
  }

  .column-left-n-middle_right {
    ${fromScreen(768)} {
      column-gap: 4px;
      grid-template-columns: 216px 1fr;
    }
  }
  .column-middle-n-right {
    .column-right .column {
      border-bottom: none;
      padding-bottom: 0;
    }
    ${fromScreen(1128)} {
      column-gap: 16px;
      grid-template-columns: 216px 1fr;
    }
  }
`;
