import * as React from 'react';
import cl from 'classnames';
import { SingleColumn } from './SingleColumn';

export const ColumnList = ({ data, className }) => {
  return (
    <div className={cl('column-list-wrapper', className)}>
      <div className={cl('column-list')}>
        {data.columns.map((col, colIndex) => {
          return <SingleColumn key={col.title + colIndex} data={col} />;
        })}
      </div>
    </div>
  );
};
