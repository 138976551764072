import { getCorpWebsiteUrl, getLandingUrlFromPathWithoutCheckingApp } from '@doltech/utils/lib/url';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import withHydrationOnDemand from '@doltech/core/lib/hooks/withDolHydrationOnDemand';
import styled from 'styled-components';

import { DolLogoFooter } from '../../../DolLogo';
import { Link } from '../../../Link';
import { ResponsiveTypography } from '../../../Typography/v3/ResponsiveTypography';
import { useLandingSharedDataStore } from '../model/landing-shared-data.hook';
import { ColumnList } from './components/ColumnList';
import { FooterIconMapper } from './components/FooterIconMapper';
import { FooterCopyRight } from './FooterCopyRight/FooterCopyRight';
import { RowLink } from './RowLink/RowLink';
import { OldSharedFooterStyles } from './SharedFooter.styles';
import { FooterData } from './SharedFooter.types';
import { environment } from '@doltech/core/lib/environment/environment';

const Main = styled.div.withConfig({
  componentId: 'SharedFooter_Main',
} as any)`
  ${OldSharedFooterStyles}
`;

export const columnMain = {
  title: 'Tự học IELTS',
  metadata: [
    {
      value: (
        <>
          Một sản phẩm thuộc Học viện Tiếng Anh Tư Duy DOL English (IELTS Đình Lực) -{' '}
          <a rel="nofollow" className="my-link" href="https://www.dolenglish.vn/">
            www.dolenglish.vn
          </a>
        </>
      ),
    },
    {
      meta: 'Trụ sở',
      link: {
        link: 'https://g.page/dolenglish?share',
        text: 'Hẻm 458/14, đường 3/2, P12, Q10, TP.HCM',
        external: true,
        noFollow: true,
      },
      value: 'Hẻm 458/14, đường 3/2, P12, Q10, TP.HCM',
    },
    {
      meta: 'Hotline',
      value: '1800 96 96 39',
      link: {
        link: 'tel:+1800969639',
        text: '1800 96 96 39',
        external: true,
        noFollow: true,
      },
    },
    {
      meta: 'Inbox',
      value: 'm.me/dolenglish.ieltsdinhluc',
      link: {
        link: 'https://m.me/dolenglish.ieltsdinhluc',
        text: 'm.me/dolenglish.ieltsdinhluc',
        external: true,
        noFollow: true,
      },
    },
  ],

  footer: [
    {
      link: 'https://www.facebook.com/dolvn',
      text: 'Facebook',
      external: true,
      noFollow: true,
      icon: 'facebook',
    },
    {
      link: 'https://www.youtube.com/channel/UCEhPLLt928jxgOGDtyzgV9g',
      text: 'Youtube',
      external: true,
      noFollow: true,
      icon: 'youtube',
    },
    {
      link: 'https://www.tiktok.com/@dolenglish',
      text: 'Tiktok',
      external: true,
      noFollow: true,
      icon: 'tiktok',
    },
  ],
};

export const ColumnMain = () => {
  return (
    <div className="column column-main">
      <div className="header">
        <div className="header-content">
          {columnMain.metadata.map((item, index) => {
            const { meta, value, link } = item;
            return (
              <ResponsiveTypography.Paragraph color="white100" key={index} variant="regular/14-20">
                {meta && (
                  <ResponsiveTypography.Text variant="bold/14-20">
                    {meta}:&nbsp;
                  </ResponsiveTypography.Text>
                )}
                {link ? (
                  <RowLink variant="regular/14-20" color="white100" prefetch={false} row={link} />
                ) : (
                  value
                )}
              </ResponsiveTypography.Paragraph>
            );
          })}
        </div>
      </div>

      <ResponsiveTypography.Paragraph color="white100" variant="Bold/14px | 20px" mb="8px">
        Theo dõi DOL tại
      </ResponsiveTypography.Paragraph>
      <div className="footer">
        {columnMain.footer.map((row, rowIndex) => {
          const Icon = FooterIconMapper[row.icon];
          return (
            <Link
              prefetch={false}
              target="_blank"
              className="btn btn-icon"
              rel="nofollow"
              key={rowIndex}
              href={row.link || '#'}
            >
              <Icon />
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export const SharedFooterBase = observer(({ renderLogo = () => <DolLogoFooter /> }: any) => {
  const store = useLandingSharedDataStore();
  const pageLink = React.useMemo(() => {
    if (store) {
      return store.pageLink;
    }
    return undefined;
  }, [store]);

  const data: FooterData = React.useMemo(() => {
    return {
      columnLeft: {
        columns: [
          {
            title: 'LUYỆN THI IELTS',
            content: [
              pageLink?.staticPageLink?.LANDING_FULL_TEST?.url && {
                link: pageLink?.staticPageLink?.LANDING_FULL_TEST?.url,
                text: 'IELTS Online Test',
                external: false,
              },
              pageLink?.staticPageLink?.LANDING_READING_PRACTICE_TEST?.url && {
                link: pageLink?.staticPageLink?.LANDING_READING_PRACTICE_TEST?.url,
                text: 'IELTS Reading Practice',
                external: false,
              },
              pageLink?.staticPageLink?.LANDING_LISTENING_PRACTICE_TEST?.url && {
                link: pageLink?.staticPageLink?.LANDING_LISTENING_PRACTICE_TEST?.url,
                text: 'IELTS Listening Practice',
                external: false,
              },
            ].filter(Boolean),
          },
          {
            title: 'TIẾNG ANH TỔNG QUÁT',
            content: [
              pageLink?.staticPageLink?.LANDING_DICTATION?.url && {
                link: pageLink?.staticPageLink?.LANDING_DICTATION?.url,
                text: 'Chép chính tả',
                external: false,
              },
              pageLink?.staticPageLink?.LANDING_DAILY_LEARNING_NEWS?.url && {
                link: pageLink?.staticPageLink?.LANDING_DAILY_LEARNING_NEWS?.url,
                text: 'Tin tức tiếng anh',
                external: false,
              },
            ].filter(Boolean),
          },
        ],
      },
      columnMiddle: {
        columns: [
          {
            title: 'VỀ DOL IELTS ĐÌNH LỰC',
            content: [
              {
                link: getCorpWebsiteUrl('/gioi-thieu-linearthinking'),
                text: 'Linearthinking',
                external: true,
                noFollow: true,
              },
              {
                link: getCorpWebsiteUrl('/nen-tang-cong-nghe'),
                text: 'Nền tảng công nghệ',
                external: true,
                noFollow: true,
              },
              {
                link: getCorpWebsiteUrl('/linearteachers'),
                text: 'Đội ngũ giáo viên',
                external: true,
                noFollow: true,
              },
              {
                link: getCorpWebsiteUrl('/hall-of-fame'),
                text: 'Thành tích học viên',
                external: true,
                noFollow: true,
              },
              {
                link: getCorpWebsiteUrl('/khoa-hoc-ielts'),
                text: 'Khóa học tại DOL ',
                external: true,
                noFollow: true,
              },
              {
                link: 'https://www.topcv.vn/',
                text: 'Tạo CV và tìm việc miễn phí',
                external: true,
              },
            ].filter(Boolean),
          },
        ],
      },

      columnRight: {
        columns: [
          {
            title: 'DOL ECOSYSTEM',
            content: [
              {
                link: environment.LANDING_GRAMMAR_FULL_URL,
                text: 'DOL Grammar',
                external: true,
              },
              {
                link: getLandingUrlFromPathWithoutCheckingApp(
                  pageLink?.staticPageLink?.LANDING_DICTIONARY?.url || '/',
                  'landing-dictionary'
                ),
                text: 'DOL Dictionary',
                external: true,
              },
              {
                link: getLandingUrlFromPathWithoutCheckingApp(
                  pageLink?.staticPageLink?.LANDING_IELTS_KNOWLEDGE?.url,
                  'landing-blog'
                ),
                text: 'Kiến thức IELTS tổng hợp',
                external: true,
              },
              {
                link: 'https://superlms.dolenglish.vn/',
                text: 'DOL superLMS',
                external: true,
              },
            ].filter(Boolean),
          },
        ],
      },
    };
  }, [
    pageLink?.staticPageLink?.LANDING_FULL_TEST?.url,
    pageLink?.staticPageLink?.LANDING_READING_PRACTICE_TEST?.url,
    pageLink?.staticPageLink?.LANDING_LISTENING_PRACTICE_TEST?.url,
    pageLink?.staticPageLink?.LANDING_DICTATION?.url,
    pageLink?.staticPageLink?.LANDING_DAILY_LEARNING_NEWS?.url,
    pageLink?.staticPageLink?.LANDING_DICTIONARY?.url,
    pageLink?.staticPageLink?.LANDING_IELTS_KNOWLEDGE?.url,
  ]);

  const { columnLeft, columnMiddle, columnRight } = data;
  return (
    <Main id="shared-footer" className="responsive-container">
      <div className="responsive-section">
        {renderLogo()}
        <div id="footer-three-column">
          <ColumnMain />
          <div className="column-left-n-middle_right">
            <ColumnList data={columnLeft} className="column-left" />
            <div className="column-middle-n-right">
              <ColumnList data={columnMiddle} className="column-middle" />
              <ColumnList data={columnRight} className="column-right" />
            </div>
          </div>
        </div>

        <FooterCopyRight />
      </div>
    </Main>
  );
});

export const SharedFooter = withHydrationOnDemand({ on: ['idle'] })(SharedFooterBase);
